//      

const StyleLayer = require('../style_layer');
const SymbolBucket = require('../../data/bucket/symbol_bucket');
const resolveTokens = require('../../util/token');
const {isExpression} = require('../../style-spec/expression');
const assert = require('assert');

                                                                           
                                                        

class SymbolStyleLayer extends StyleLayer {

    getLayoutValue(name        , globals                  , feature          )      {
        const value = super.getLayoutValue(name, globals, feature);
        if (value !== 'auto') {
            return value;
        }

        switch (name) {
        case 'text-rotation-alignment':
        case 'icon-rotation-alignment':
            return this.getLayoutValue('symbol-placement', globals, feature) === 'line' ? 'map' : 'viewport';
        case 'text-pitch-alignment':
            return this.getLayoutValue('text-rotation-alignment', globals, feature);
        case 'icon-pitch-alignment':
            return this.getLayoutValue('icon-rotation-alignment', globals, feature);
        default:
            return value;
        }
    }

    getLayoutDeclaration(name        ) {
        return this._layoutDeclarations[name];
    }

    isLayoutValueFeatureConstant(name        ) {
        const declaration = this._layoutDeclarations[name];
        return !declaration || declaration.isFeatureConstant();
    }

    isLayoutValueZoomConstant(name        ) {
        const declaration = this._layoutDeclarations[name];
        return !declaration || declaration.isZoomConstant();
    }

    getValueAndResolveTokens(name                             , globals                  , feature         ) {
        const value = this.getLayoutValue(name, globals, feature);
        const declaration = this._layoutDeclarations[name];
        if (this.isLayoutValueFeatureConstant(name) && !isExpression(declaration.value)) {
            return resolveTokens(feature.properties, value);
        }

        return value;
    }

    createBucket(parameters                  ) {
        // Eventually we need to make SymbolBucket conform to the Bucket interface.
        // Hack around it with casts for now.
        return (new SymbolBucket((parameters     ))     );
    }

    isOpacityZero(zoom        , property        ) {
        return this.isPaintValueFeatureConstant(property) &&
            this.getPaintValue(property, { zoom: zoom }) === 0;
    }

    queryRadius()         {
        return 0;
    }

    queryIntersectsFeature()          {
        assert(false); // Should take a different path in FeatureIndex
        return false;
    }
}

module.exports = SymbolStyleLayer;
