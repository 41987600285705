//      

const StyleLayer = require('../style_layer');
const FillExtrusionBucket = require('../../data/bucket/fill_extrusion_bucket');
const {multiPolygonIntersectsMultiPolygon} = require('../../util/intersection_tests');
const {translateDistance, translate} = require('../query_utils');

                                                                           
                                                        
                                                

class FillExtrusionStyleLayer extends StyleLayer {

    getPaintValue(name        , globals                  , feature          ) {
        const value = super.getPaintValue(name, globals, feature);
        if (name === 'fill-extrusion-color' && value) {
            value.a = 1;
        }
        return value;
    }

    createBucket(parameters                  ) {
        return new FillExtrusionBucket(parameters);
    }

    isOpacityZero(zoom        ) {
        return this.getPaintValue('fill-extrusion-opacity', { zoom: zoom }) === 0;
    }

    queryRadius()         {
        return translateDistance(this.paint['fill-extrusion-translate']);
    }

    queryIntersectsFeature(queryGeometry                     ,
                           feature                   ,
                           geometry                     ,
                           zoom        ,
                           bearing        ,
                           pixelsToTileUnits        )          {
        const translatedPolygon = translate(queryGeometry,
            this.getPaintValue('fill-extrusion-translate', {zoom}, feature),
            this.getPaintValue('fill-extrusion-translate-anchor', {zoom}, feature),
            bearing, pixelsToTileUnits);
        return multiPolygonIntersectsMultiPolygon(translatedPolygon, geometry);
    }

    has3DPass() {
        return this.paint['fill-extrusion-opacity'] !== 0 && this.layout['visibility'] !== 'none';
    }

    resize(gl                       ) {
        if (this.viewportFrame) {
            const {texture, fbo} = this.viewportFrame;
            gl.deleteTexture(texture);
            gl.deleteFramebuffer(fbo);
            this.viewportFrame = null;
        }
    }
}

module.exports = FillExtrusionStyleLayer;
