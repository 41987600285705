//      

                                                
                                                     
                                     

class Curve                       {
               

    static parse(args              , context                ) {
        const [ , interpolation, input, ...rest] = args;
        if ((interpolation     )[0] === "step") {
            return context.error(`"curve" has been replaced by "step" and "interpolate". Replace this expression with ${
                JSON.stringify(["step", input, ...rest])}`, 0);
        } else {
            return context.error(`"curve" has been replaced by "step" and "interpolate". Replace this expression with ${
                JSON.stringify(["interpolate", interpolation, input, ...rest])}`, 0);
        }
    }

    evaluate() {}
    eachChild() {}
}

module.exports = Curve;
