//      

const StyleLayer = require('../style_layer');
const CircleBucket = require('../../data/bucket/circle_bucket');
const {multiPolygonIntersectsBufferedMultiPoint} = require('../../util/intersection_tests');
const {getMaximumPaintValue, translateDistance, translate} = require('../query_utils');

                                                                
                                                

class CircleStyleLayer extends StyleLayer {
    createBucket(parameters                  ) {
        return new CircleBucket(parameters);
    }

    isOpacityZero(zoom        ) {
        return this.isPaintValueFeatureConstant('circle-opacity') &&
            this.getPaintValue('circle-opacity', { zoom: zoom }) === 0 &&
            ((this.isPaintValueFeatureConstant('circle-stroke-width') &&
                this.getPaintValue('circle-stroke-width', { zoom: zoom }) === 0) ||
            (this.isPaintValueFeatureConstant('circle-stroke-opacity') &&
                this.getPaintValue('circle-stroke-opacity', { zoom: zoom }) === 0));
    }

    queryRadius(bucket        )         {
        const circleBucket               = (bucket     );
        return getMaximumPaintValue('circle-radius', this, circleBucket) +
            getMaximumPaintValue('circle-stroke-width', this, circleBucket) +
            translateDistance(this.paint['circle-translate']);
    }

    queryIntersectsFeature(queryGeometry                     ,
                           feature                   ,
                           geometry                     ,
                           zoom        ,
                           bearing        ,
                           pixelsToTileUnits        )          {
        const translatedPolygon = translate(queryGeometry,
            this.getPaintValue('circle-translate', {zoom}, feature),
            this.getPaintValue('circle-translate-anchor', {zoom}, feature),
            bearing, pixelsToTileUnits);
        const radius = this.getPaintValue('circle-radius', {zoom}, feature) * pixelsToTileUnits;
        const stroke = this.getPaintValue('circle-stroke-width', {zoom}, feature) * pixelsToTileUnits;
        return multiPolygonIntersectsBufferedMultiPoint(translatedPolygon, geometry, radius + stroke);
    }
}

module.exports = CircleStyleLayer;
